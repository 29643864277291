import React from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { listDash } from 'helpers/listDashboard';
import CardDash from 'components/card/cardDash';
import { CaretDownOutlined } from '@ant-design/icons';

const ListDash = () => {
  const history = useHistory();
  return (
    <>
      {listDash.map((item) => (
        <div key={item.id} className="mt-4">
          <Typography.Title
            level={4}
            className="text-secondary"
            style={{ marginBlockEnd: 0 }}
          >
            <CaretDownOutlined className="size-12" /> {item.name}
          </Typography.Title>
          <div className="card__list-wrapper" style={{ marginTop: 5 }}>
            {item.children.map(({ href, ...i }) => (
              <CardDash key={i.id} {...i} onClick={() => history.push(href)} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ListDash;
