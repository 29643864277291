import React from 'react';

const CardTemplate = ({
  body,
  name,
  active,
  uri_img,
  ondblclick,
  ...props
}: IProps) => {
  return (
    <div className={`card-template ${active ? 'active' : ''}`} {...props}>
      <div className="layer" onDoubleClick={ondblclick} />
      {!!uri_img ? (
        <div className="card-template-body not-scroll">
          <img
            src={uri_img}
            alt={name}
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <div
          className="card-template-body not-scroll"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
      <div className="card-template-footer">
        <h4>{name}</h4>
      </div>
    </div>
  );
};

interface IProps {
  body: string;
  name: string;
  active: boolean;
  uri_img?: string;
  onClick: () => void;
  ondblclick: () => void;
}

export default CardTemplate;
