import React from 'react';
import { Button, Input, Table, Typography } from 'antd';
import { columnLabels } from 'config/antd/table.config';
import { FullscreenOutlined, LeftCircleFilled } from '@ant-design/icons';

const AsideContacts = (props: IProps) => {
  const { data, handleModal, handleContact, open, setOpen } = props;

  return (
    <div className={open ? 'shared_menu_open' : 'shared_menu_close'}>
      <Button
        icon={<FullscreenOutlined />}
        className="shared_menu_hidden"
        onClick={setOpen}
      />
      {open && (
        <div className="shared__labels animate__animated animate__slideInLeft animate__faster">
          <Typography.Title className="text-center" level={4}>
            Labels
          </Typography.Title>
          <Input placeholder="input search text" />
          <Table
            className="mt-2"
            dataSource={data}
            columns={columnLabels(handleModal)}
            onRow={(record: any) => ({
              onClick: () => handleContact(record?.id),
            })}
          />

          <div className="shared_menu_icon" onClick={setOpen}>
            <LeftCircleFilled />
          </div>
        </div>
      )}
    </div>
  );
};

interface IProps {
  open: boolean;
  data: any;
  handleModal: any;
  handleContact: any;
  setOpen: () => void;
}

export default AsideContacts;
