import React from 'react';
import { Typography } from 'antd';
import ListDash from './component/listDash';

const { Title } = Typography;

const DashboardPage = () => {
  return (
    <div
      className="animate__animated animate__fadeIn mb-5"
      style={{ minHeight: '75vh' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        Dashboard
      </Title>
      <Title
        className="text-secondary"
        level={5}
        style={{ marginBlockStart: 0 }}
      >
        Welcome to Zumpul | Extending Google Apps
      </Title>
      <ListDash />
    </div>
  );
};

export default DashboardPage;
