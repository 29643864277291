import { Button } from 'antd';
import MenuAside from '../Menu';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CloseBurger from './closeIBurger';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import useStateRedux from 'hooks/useStateRedux';
import { handleMenuAction } from 'utils/handleMenuAction';
import { handleOpenAside } from 'redux/actions/ui.action';
import { assets } from '../../helpers/assets';
import { handleResetForm } from '../../redux/actions/forms.action';
import { formSignature } from '../../utils/formConstant';

const Aside = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { aside }: any = useStateRedux('ui');

  const { open, isMobile, animationAside } = aside;

  const handleShow = () => dispatch(handleOpenAside());
  const newSignature = (e: any) => {
    e.preventDefault();
    dispatch(handleResetForm(formSignature));
    history.push('/signatureCreate');
    handleMenuAction(dispatch);
  };

  useEffect(() => {
    if (isMobile) dispatch(handleOpenAside());
  }, [isMobile, dispatch]);

  useEffect(() => handleMenuAction(dispatch), [dispatch, history.location]);

  return (
    <div>
      <div className={`dashboard__aside`}>
        <CloseBurger setShow={handleShow} show={open} />
        <div
          className={`dashboard__aside-menu ${
            animationAside ? 'close-animation' : ''
          } ${!open ? 'deactivate' : 'activate'}`}
        >
          <div className="dashboard__aside-menu-position">
            <div
              className="dashboard__aside-brand pointer"
              onClick={() => history.push('/')}
            >
              <img width={130} src={assets.zumpul_brand} alt="Aeegle Inc." />
            </div>
            <div className="mt-2 dashboard__aside-content">
              <a href="/signatureCreate">
                <Button
                  size="large"
                  type="dashed"
                  className="btn-primary"
                  onClick={newSignature}
                  icon={<PlusOutlined />}
                  style={{ width: '100%' }}
                >
                  Create new signature
                </Button>
              </a>

              {/*Menu Aside*/}
              <MenuAside />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aside;
