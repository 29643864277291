// @ts-ignore
import countryList from 'react-select-country-list';
import { useGoogleApi } from 'react-gapi';
import logout from 'config/google/logout';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import PhoneInput from 'react-phone-input-2';
import { scopes } from 'config/google/scopes';
import { useHistory } from 'react-router-dom';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import useFetchSuit from 'api/authAndCustomer/verifySuit.api';
import React, { useEffect, useState } from 'react';
import createdCustomer from 'api/authAndCustomer/actionsCustomer.api';
import { Col, Form, Input, Row, Select, Typography } from 'antd';
import {
  setAuthenticate,
  setGoogleAuthenticate,
} from 'redux/actions/auth.action';

const CompleteForm = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes: scopes });
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { google }: any = useStateRedux('auth');
  const auth = gapi?.auth2?.getAuthInstance();
  const { data, error, loading } = useFetchSuit();
  const [country, setCountry] = useState([]);

  const onFinish = async ({ country, ...values }: any) => {
    const newValues = { ...data, ...values, ...JSON.parse(country) };
    const { error, data: user } = await createdCustomer(newValues);

    console.log(user);

    if (error) return console.log(error);
    dispatch(
      setAuthenticate({
        ...user,
        authenticated: true,
        google: { ...newValues },
      }),
    );
    google.plan === 'Trial'
      ? history.replace('/')
      : history.replace('/checkout');
  };

  useEffect(() => {
    setCountry(countryList().getData());
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setGoogleAuthenticate(data));
      form.setFieldsValue(data);
    }
  }, [data]);

  useEffect(() => {
    if (!!error) logout(auth, history, dispatch);
  }, [error]);

  if (loading) return <Loading />;

  return (
    <div className="card__list-wrapper card__create-form">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="complete-form"
      >
        <Row gutter={20}>
          <Col span={24}>
            <Typography.Title level={3}>Contact Information</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={20}>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Full Name"
                  required
                  name="admin_full_name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="John A." size="large" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Email"
                  name="admin_email"
                  required
                  rules={[{ required: true }, { type: 'email' }]}
                >
                  <Input placeholder="example@mail.com" size="large" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  required
                  label="Phone"
                  name="phone"
                  rules={[{ required: true }]}
                >
                  <PhoneInput country={'us'} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  required
                  label="Country"
                  name="country"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    placeholder="Select an option"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {country.map((i: any) => (
                      <Select.Option
                        key={i.value}
                        value={JSON.stringify({
                          country_name: i.label,
                          country_code: i.value.toLowerCase(),
                        })}
                      >
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Job title"
                  name="job_title"
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Manager" size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={20}>
              <Col span={24} className="mt-3">
                <Typography.Title level={3}>
                  Company Information
                </Typography.Title>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Company"
                  name="summary"
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Aeegle - Zumpul" size="large" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  required
                  label="Number of employers"
                  name="number_of_employees"
                  rules={[{ required: true }]}
                >
                  <Select size="large" placeholder="Select an option">
                    {listEmployers.map((emp, i) => (
                      <Select.Option key={i} value={emp}>
                        {emp}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  required
                  name="type_gaps"
                  label="Type of Google App"
                  rules={[{ required: true }]}
                >
                  <Select size="large" placeholder="Select an option">
                    {typeGoogle.map((type, i) => (
                      <Select.Option key={i} value={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const listEmployers = [
  '1 - 49',
  '50 - 99',
  '100 - 499',
  '500 - 999',
  '1,000 - 4,999',
  '5,000 - 9,999',
];
const typeGoogle = [
  'G Suite',
  'G Suite Monthly Payment',
  'G Suite Business',
  'G Suite Enterprise',
  'G Suite for Education',
  'G Suite Monthly Payment (No Reseller)',
  'Other',
];

export default CompleteForm;
