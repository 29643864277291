import { resetAuth } from 'redux/actions/auth.action';

export default async function logout(
  auth: any,
  history: any,
  dispatch: any,
  redirect = true,
) {
  try {
    await auth.signOut();
  } catch (e) {
    console.log(e);
  }
  if (redirect) {
    dispatch(resetAuth());
    localStorage.removeItem('auth');
    history.push('/login');
  }
}
