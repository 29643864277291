import React from 'react';
import { Avatar, Tag } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { CheckCircleOutlined } from '@ant-design/icons';

const HeadUser = () => {
  const { image, email }: any = useStateRedux('auth');
  return (
    <div className="settings__head-user">
      <Avatar size={65} src={image} />
      <div className="mt-1">
        <Tag icon={<CheckCircleOutlined />} color="red">
          {email}
        </Tag>
      </div>
    </div>
  );
};

export default HeadUser;
