const configEditor = {
  uiColor: '#FFFFFF',
  allowedContent: {
    $1: {
      attributes: true,
      styles: true,
      classes: true,
    },
  },
  font_names:
    'Arial/Arial, Helvetica,sans-serif;Times New Roman, Times, Courier, Georgia, Lucida Sans, Tahoma, Trebuchet MS, serif;Verdana',
  language: 'en',
  removeButtons: '',
  colorButton_enableMore: true,
  fontSize_sizes:
    '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt',
  disallowedContent: 'div p h1 h2 h3 h4 h5 h6{background-color}',
  coreStyles_bold: { element: 'strong', overrides: 'b' },
  removeDialogTabs: '',
  extraPlugins: [
    'dragresize',
    'sourcedialog',
    'font',
    'find',
    'image',
    'uploader',
    'filebrowser',
    'tableresize',
    'horizontalrule',
    'selectall',
    'div',
    'justify',
    'bidi',
    'colorbutton',
    'showblocks',
  ],
  toolbar: [
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    {
      name: 'basicstyles',
      groups: ['basicstyles', 'cleanup'],
      items: [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
        'Subscript',
        'Superscript',
        '-',
        'RemoveFormat',
        '-',
        'TextColor',
        'BGColor',
        'Outdent',
        'Indent',
        '-',
        'Blockquote',
        'CreateDiv',
      ],
    },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
      items: [
        'JustifyLeft',
        'JustifyCenter',
        'JustifyRight',
        'JustifyBlock',
        '-',
        'NumberedList',
        'BulletedList',
        '-',
        'BidiLtr',
        'BidiRtl',
        '-',
        'Uploader',
        'Image',
        'Table',
        'HorizontalRule',
      ],
    },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },

    { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    {
      name: 'others',
      items: [
        'Cut',
        'Copy',
        'Paste',
        'PasteText',
        'PasteFromWord',
        'PasteFromGoogle',
        '-',
        'Find',
        'Replace',
        'SelectAll',
        'Scayt',
        '-',
        'Source',
        'Undo',
        'Redo',
      ],
    },
  ],
};

export default configEditor;
