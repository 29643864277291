import timezones from 'timezones-list';
import React, { useEffect, useState } from 'react';
import TimezoneSelect, { i18nTimezones } from 'react-timezone-select';

const TimeZone = (props) => {
  const [current, setCurrent] = useState('');
  const { value = null, onChange = null } = props;

  useEffect(() => {
    if (!!value && !current) {
      setCurrent(value);
    }
  }, [value]);

  useEffect(() => {
    !!onChange && onChange(current.value);
  }, [current]);

  const getTzs = () => {
    let tzs = { ...i18nTimezones };
    timezones?.forEach((i) => {
      tzs = { ...tzs, [i.tzCode]: i.name.slice(12, i.name.length) };
    });
    return tzs;
  };

  return (
    <TimezoneSelect
      value={current}
      timezones={getTzs()}
      onChange={setCurrent}
      className="custom-input"
    />
  );
};

export default TimeZone;
