import passportInstance from 'config/axios/v1Passport.instance';
import { getStorage } from '../../helpers/storage';

const getToken = () => {
  const auth: any = getStorage('auth');
  if (!!auth?.token) return auth.token;
  return '';
};

const headers = () => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json',
});

export function getPayment(body: any) {
  return passportInstance
    .post(`/payments/calculate`, body, { headers: headers() })
    .then(({ data }) => ({ data, error: null }))
    .catch((err) => ({
      data: null,
      error: err?.message || err?.response?.data,
    }));
}
