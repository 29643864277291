import { Typography } from 'antd';
import useQuery from 'hooks/useQuery';
import { useDispatch } from 'react-redux';
import { formTemplate } from 'utils/formConstant';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import CurrentCreateOutOfficeView from './components';
import ListDash from '../../components/listDash/listDash';
import { Actions } from 'components/actionsForm/actionsForm';
import { listMenuOutOffice } from '../../helpers/listDashboard';

const { Title } = Typography;

const CreateOrEditOutOfficePage = () => {
  const query = useQuery();
  const id = query.get('id');
  const dispatch = useDispatch();
  const { forms, ui }: any = useStateRedux();
  const [state, setState] = useState({
    list: listMenuOutOffice,
    active: 'information',
  });
  const { template } = forms;

  // click menu in dash card
  const onClick = (id: any) =>
    setState((prev) => {
      const list = prev.list.map((i) =>
        i.id === id ? { ...i, active: true } : { ...i, active: false },
      );
      return { ...prev, list, active: id };
    });

  useEffect(() => {
    // dispatch(!!id ?
    //   handleEditFormTemplate({id}) :
    //   handleResetForm(formTemplate)
    // )
  }, [dispatch, id]);

  if (ui.loading) return <Loading />;

  return (
    <>
      <div
        className="animate__animated animate__fadeIn"
        style={{ minHeight: '75vh', position: 'relative' }}
      >
        <Title level={1} style={{ marginBlockEnd: 0 }}>
          {template.edit.editing ? 'Edit Out Office' : 'Create Out Office'}
        </Title>

        {/*Action Buttons*/}
        <div className="view-desktop">
          <Actions type="template" form={formTemplate} />
        </div>

        {/*Menu*/}
        <ListDash
          state={state.list}
          onClick={onClick}
          title="Choose step for complete"
        />

        {/*Body*/}
        <CurrentCreateOutOfficeView active={state.active} />
      </div>
    </>
  );
};

export default CreateOrEditOutOfficePage;
