import React, { memo } from 'react';
import { Typography } from 'antd';
import CardDash from 'components/card/cardDash';

const ListDash = memo((props: IListDash) => {
  const { state, onClick, title = 'Choose step for config' } = props;
  console.log('list dash');
  return (
    <div>
      <Typography.Text className="text-secondary">
        <b>{title}:</b>
      </Typography.Text>
      <div className="mt-1 card__list-wrapper card__list-wrapper-mobile beauty-scroll">
        {state.map((i: any) => (
          <CardDash
            key={i.id}
            onClick={onClick}
            {...i}
            style={{ marginRight: 5 }}
          />
        ))}
      </div>
    </div>
  );
});

interface IListDash {
  state: any;
  onClick: any;
  title?: string;
}

export default ListDash;
