import thunk from 'redux-thunk';
import uiReducer from 'redux/reducers/ui.reducer';
import AuthReducer from 'redux/reducers/auth.reducer';
import formReducer from 'redux/reducers/forms.reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const reducers = combineReducers({
  auth: AuthReducer,
  ui: uiReducer,
  forms: formReducer,
});

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
);
