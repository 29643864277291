import axios from 'config/axios/v1.instance';
import apiV1 from 'config/axios/v1.instance';
import { useCallback, useEffect, useState } from 'react';
import axiosPassport from 'config/axios/v1Passport.instance';

export default function createdCustomer(body: any) {
  return axiosPassport
    .post('/customers', body)
    .then(({ data }) => ({ data, error: null }))
    .catch((err) => ({
      data: null,
      error: err?.message || err?.response?.data,
    }));
}

export function useGetCustomerById() {
  const [state, setState] = useState({ customer: null, loading: true });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }));
    axios('/customers/info', {
      baseURL: process.env.REACT_APP_PASSPORT_API,
    })
      .then(({ data }) => {
        console.log(data);
        setState({
          customer: data,
          loading: false,
        });
      })
      .catch(() => setState({ customer: null, loading: false }));
  }, []);

  return { ...state };
}

export const useFetchAdminsCustomer = () => {
  // definition state
  const [data, setData] = useState<any[]>([]);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    apiV1
      .get<any[]>(`/customers/admins`, {
        baseURL: process.env.REACT_APP_PASSPORT_API,
      })
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, []);

  useEffect(() => {
    getDataServer();
  }, [getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

export const addAdminCustomer = (body: any) => {
  return apiV1
    .post('/customers/grant-access', body, {
      baseURL: process.env.REACT_APP_PASSPORT_API,
    })
    .then(({ data }) => ({ data, error: null }))
    .catch((err) => ({
      data: null,
      error: err?.message || err?.response?.data,
    }));
};

export const removeAccessCustomer = (body: any) => {
  return apiV1
    .post('/customers/remove-access', body, {
      baseURL: process.env.REACT_APP_PASSPORT_API,
    })
    .then(({ data }) => ({ data, error: null }))
    .catch((err) => ({
      data: null,
      error: err?.message || err?.response?.data,
    }));
};
