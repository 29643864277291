import CKEditor from 'ckeditor4-react';
import React, { useRef, useState } from 'react';
import { editorCustomField } from '../../utils/utils';
import configEditor from '../../services/ckEditorToolbar';
import { insertAttr } from '../../services/plugin/insertName';
import { Button, Modal, Select, Tooltip, Typography } from 'antd';

const { Option } = Select;

CKEditor.editorUrl = `${process.env.PUBLIC_URL}/ckeditor/ckeditor.js`;
const initFunction = () => {};

const EditorComponent = ({
  data = '',
  onChangeEditor = initFunction,
  title = 'Design signature',
}) => {
  const ref = useRef(null);

  const clickAttr = (name) =>
    !!ref.current?.editor && insertAttr(ref.current?.editor, name);

  const showData = () => {
    !!onChangeEditor && onChangeEditor(ref.current?.editor?.getData());
  };

  return (
    <div className="custom-plugin-editor-wrapper">
      <div className="custom-plugin-editor-editor">
        <div className="mb-1 ant-form-item-label">
          <label
            htmlFor="template_summary"
            className="ant-form-item-required"
            title="Description/Summary"
          >
            {title}
          </label>
        </div>
        <CKEditor
          ref={ref}
          data={data}
          onChange={showData}
          config={configEditor}
        />
      </div>
      <CustomActions clickAttr={clickAttr} />
    </div>
  );
};

const CustomActions = ({ clickAttr }) => {
  const [visible, setVisible] = useState(false);
  const handleVisible = () => setVisible(!visible);
  const onClickModal = (insert) => clickAttr(insert);
  return (
    <>
      <ModalIf
        visible={visible}
        handleVisible={handleVisible}
        onClick={onClickModal}
      />
      <div
        className="card__list-wrapper custom-plugin-editor-buttons"
        style={{ minHeight: 'auto' }}
      >
        <div className="mb-1" style={{ width: '100%' }}>
          <b>Conditional:</b>
        </div>
        <Tooltip title="Create a condition in the editor from code">
          <Button
            type="dashed"
            onClick={() => clickAttr('#{if} #{/if}')}
            className="mr-1"
          >
            Manual IF
          </Button>
        </Tooltip>
        <Tooltip title="Launch a modal to create a condition with the personals fields">
          <Button type="dashed" onClick={handleVisible} className="mr-1">
            Helper IF
          </Button>
        </Tooltip>

        <div className="mb-1" style={{ width: '100%' }}>
          <b>Personal Fields:</b>
        </div>
        {editorCustomField.map((i) => (
          <Tooltip key={i.id} title={i.tooltip}>
            <Button
              type="dashed"
              onClick={() => clickAttr(i.insert)}
              className="mr-1"
            >
              {i.title}
            </Button>
          </Tooltip>
        ))}
      </div>
    </>
  );
};

const ModalIf = ({ visible, handleVisible, onClick }) => {
  const [state, setState] = useState([]);
  const key = state === [] ? '' : state;

  const onChange = (val) => setState(val);
  const afterClose = () => setState([]);
  const onFinish = () => {
    onClick(`#{if ${key}} ${'${' + key + '}'} #{/if}`);
    handleVisible();
  };

  return (
    <Modal
      footer={null}
      visible={visible}
      afterClose={afterClose}
      onCancel={handleVisible}
      title="Create a condition in the editor"
    >
      <div className="mb-2">
        <Select
          showSearch
          size="large"
          value={state}
          onChange={onChange}
          style={{ width: '100%' }}
          optionFilterProp="children"
          placeholder="Select a person"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {editorCustomField.map((i) => (
            <Option key={i.id} value={i.id}>
              {i.title}
            </Option>
          ))}
        </Select>

        {/*Preview if*/}
        <div className="mt-1">
          <Typography.Title level={5}>Preview</Typography.Title>
          <Typography.Text code>{`#{if ${key}} ${
            '${' + key + '}'
          } #{/if}`}</Typography.Text>
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={handleVisible} type="default" className="mr-1">
          Cancel
        </Button>
        <Button onClick={onFinish} type="primary">
          Insert condition
        </Button>
      </div>
    </Modal>
  );
};

export default EditorComponent;
