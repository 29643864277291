import React from 'react';
import { Button, Typography } from 'antd';
import { assets } from '../helpers/assets';
import Footer from './footer/footer';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;

const SuccessInstallation = () => {
  const history = useHistory();
  return (
    <div
      style={{ overflowY: 'scroll', height: '100vh' }}
      className="beauty-scroll"
    >
      <div className="auth__content animate__animated animate__fadeIn">
        <div className="auth__wrapper_login">
          <div
            className="auth__login-content inverted animate__animated animate__fadeInLeft"
            style={{ backgroundImage: `url('${assets.success}')` }}
          >
            <div style={{ maxWidth: 400 }}>
              <Title level={1} className="text-white">
                Thanks for shopping with us
              </Title>
            </div>
          </div>

          <div
            className="auth__info animate__animated animate__fadeIn d-fle"
            style={{
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ textAlign: 'center', maxWidth: 400 }}>
              <img
                width={195}
                src={assets.zumpul_img}
                style={{ marginBottom: 20 }}
                alt="Zumpul Signature Email template"
              />
              <Title level={3}>
                It's time to make the most of your Workspaces
              </Title>
              <Button
                type="primary"
                size="large"
                onClick={() => history.replace('/?refresh=true')}
              >
                Go to home
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />;
    </div>
  );
};

export default SuccessInstallation;
