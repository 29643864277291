import React, { useEffect } from 'react';
import HeadUser from './headUser';
import Col from 'antd/es/grid/col';
import { Form, Input, Row } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { useForm } from 'antd/es/form/Form';

const ProfileForm = () => {
  const [form] = useForm();
  const auth: any = useStateRedux('auth');

  useEffect(() => {
    form.setFieldsValue(auth);
  }, [form]);

  return (
    <div className="settings__wrapper animate__animated animate__fadeIn mb-5">
      <HeadUser />

      {/*Form profile*/}
      <Form form={form} className="mt-2" layout="vertical" name="form-profile">
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item name="user_name" label="First Name:" required>
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Last Name:" name="user_last_name" required>
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Title:" name="job_title">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Department:" name="department">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Company:" name="summary">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Manager:" name="manager">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Phone:" name="phone">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ProfileForm;
