import React, { useState } from 'react';
import { Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { onDeleteSignature } from 'helpers/deleteRecord';
import { columnsSignature } from 'config/antd/table.config';
import { ActionsNew } from 'components/actionsForm/actionsForm';
import { useFetchSignatureList } from 'api/signature/signature.api';

const { Title } = Typography;

const SignatureListPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, loading, refresh } = useFetchSignatureList(page);

  const createSignature = () => history.push('/signatureCreate');
  const handleTableChange = (pagination: any) => setPage(pagination.current);
  const onDelete = (event: any, item: any) =>
    onDeleteSignature(event, item, refresh);
  const onClick = (payload: any) =>
    history.push(`/signatureCreate?id=${payload.id}`);

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        Signature List
      </Title>
      <ActionsNew text="Create Signature" onClick={createSignature} />

      <div className="mt-3 table-responsive">
        <Table
          loading={loading}
          dataSource={data.items}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          columns={columnsSignature(onDelete)}
          onRow={(record: any) => ({ onClick: () => onClick(record) })}
          pagination={{
            showSizeChanger: false,
            pageSize: 25,
            total: data.total_items,
            current: page,
          }}
        />
      </div>
    </div>
  );
};

export default SignatureListPage;
