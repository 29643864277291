import React from 'react';
import ProfileForm from './profile';
import GeneralForm from './general';
import CollaboratorForm from './collaborator';

const CurrentSettingsView = ({ active }: IActionProps) => {
  switch (active) {
    case 'profile':
      return <ProfileForm />;
    case 'general':
      return <GeneralForm />;
    case 'administrators':
      return <CollaboratorForm />;

    default:
      return <ProfileForm />;
  }
};

interface IActionProps {
  active: 'profile' | 'general' | 'administrators' | string;
}

export default CurrentSettingsView;
