import React from 'react';
import { Result } from 'antd';

const NotData = ({
  title = 'You still do not have data available',
  subTitle = 'if you think this is an error, you can contact us.',
}: IProps) => {
  return <Result title={title} subTitle={subTitle} />;
};

interface IProps {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
}

export default NotData;
