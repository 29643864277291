import React from 'react';
import { Row, Typography } from 'antd';
import { dataPricing } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardPricing from 'components/card/cardPricing';
import { setGoogleAuthenticate } from 'redux/actions/auth.action';

const data = [...dataPricing];

const UpgradeSub = () => {
  return (
    <div className="card__create-form card__list-wrapper mb-5">
      <div className="mt-3" style={{ width: '100%' }}>
        <div className="mb-4">
          <Typography.Title level={2}>Upgrade Plan</Typography.Title>
          <Typography.Text>
            <b>Note:</b> You need to pay licenses for all collaborators from
            your Google Workspace(G Suite) Account
          </Typography.Text>
        </div>
        <div className="mr-auto ml-auto mt-5">
          <ListPricing />
        </div>
      </div>
    </div>
  );
};

// List plans
const ListPricing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleActive = (plan: string, current: string, e: any) => {
    e.preventDefault();
    dispatch(setGoogleAuthenticate({ plan, currentMembership: current }));
    history.push('/checkout');
  };

  return (
    <Row gutter={[20, 20]}>
      {data.map((i: any) => (
        <CardPricing
          {...i}
          key={i.id}
          active={false}
          onClick={() => {}}
          buttonClick={handleActive}
          head={(props: any) => <CardPricing.HeadPricing {...i} {...props} />}
        />
      ))}
    </Row>
  );
};

export default UpgradeSub;
