import { Button, Popconfirm, Tooltip } from 'antd';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useStateRedux from 'hooks/useStateRedux';
import SelectPeople from 'components/selectPeople/selectPeople';
import {
  addEntitySignature,
  removeEntitySignature,
} from 'redux/actions/forms.action';

const ChoosePeopleForm = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { signature }: any = useStateRedux('forms');
  const [type, setType] = useState<'PERSON' | 'GROUP' | ''>('');

  const onSearch = useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  const onAddEntity = (record: any) => dispatch(addEntitySignature(record));

  const onRemoveEntity = (record: any) =>
    dispatch(removeEntitySignature(record.id));

  const handleType = (t: 'PERSON' | 'GROUP' | '', value: string) => {
    console.log(value);
    setType(t);
    setSearch(value);
  };

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <div className="signatures__actionsWrapper">
        <TitleHead />
        <SelectPeople
          type={type}
          onSearch={onSearch}
          searchValue={search}
          addEntity={onAddEntity}
          removeEntity={onRemoveEntity}
          entities={signature.entities}
          tooltip={(props: any) => <Popconfirm {...props} />}
          extra={({ value }: { value: string }) => (
            <>
              <Tooltip title="Get list from all members in Google Suite">
                <Button
                  type="text"
                  size="large"
                  onClick={() => handleType('', value)}
                  className="signatures__actionsBtn"
                >
                  All
                </Button>
              </Tooltip>
              <Tooltip title="Get list from all members in Google Suite">
                <Button
                  type="text"
                  size="large"
                  onClick={() => handleType('PERSON', value)}
                  className="signatures__actionsBtn"
                >
                  PERSONS
                </Button>
              </Tooltip>
              <Tooltip title="Select a custom group">
                <Button
                  size="large"
                  type="text"
                  className="signatures__actionsBtn"
                  onClick={() => handleType('GROUP', value)}
                >
                  Group
                </Button>
              </Tooltip>
            </>
          )}
        />
      </div>
    </div>
  );
};

const TitleHead = memo(() => (
  <div style={{ textAlign: 'left' }}>
    <div className="text-secondary">
      Select the people or Google group to apply this signature.
      <span className="text-secondary">
        {' '}
        When you select a Google group, the signature will be applied to all
        it's members.
      </span>
    </div>
  </div>
));

export default ChoosePeopleForm;
