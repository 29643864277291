import React, { useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { useFetchEntityList } from 'api/entities/entities.api';

const ModalShare = ({ visible, handleModal }: IProps) => {
  const [search, setSearch] = useState(null);
  const { data, loading } = useFetchEntityList({ page: 1, search });

  const options = data.items.map((contact) => ({
    label: contact.name,
    value: contact.id,
  }));

  const onSearch = (value: any) => setSearch(value);

  return (
    <Modal
      bodyStyle={{ paddingBottom: 35 }}
      title="Shared with others"
      visible={visible}
      onCancel={() => handleModal(false)}
    >
      <Form.Item label="Invite" labelCol={{ span: 24 }}>
        <Select
          mode="multiple"
          filterOption={false}
          onSearch={onSearch}
          style={{ width: '100%' }}
          placeholder="Search people"
          loading={loading}
          options={options}
        />
      </Form.Item>
    </Modal>
  );
};

interface IProps {
  handleModal: (visible: boolean) => void;
  okClick?: (visible: boolean) => void;
  visible: boolean;
}

export default ModalShare;
