export const assets = {
  electronic_img: `${process.env.PUBLIC_URL}/bg-electronic.png`,
  zumpul_brand: `${process.env.PUBLIC_URL}/brand2.svg`,
  zumpul_img: `${process.env.PUBLIC_URL}/zumpul_logo.svg`,
  zumpul_img2: `${process.env.PUBLIC_URL}/brand2.svg`,
  zumpul_bg_auth: `${process.env.PUBLIC_URL}/bg-auth2.svg`,
  google_icon: `${process.env.PUBLIC_URL}/google_icon.svg`,
  aeegle_icon: `${process.env.PUBLIC_URL}/aeegle.png`,
  success: `${process.env.PUBLIC_URL}/success.png`,
};
