import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import SelectPeople from 'components/selectPeople/selectPeople';
import { uiHandleOptionalLoading } from 'redux/actions/ui.action';
import {
  addAdminCustomer,
  removeAccessCustomer,
  useFetchAdminsCustomer,
} from 'api/authAndCustomer/actionsCustomer.api';

const { Title } = Typography;

const CollaboratorForm = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { data, refresh } = useFetchAdminsCustomer();
  const { optionalLoading }: any = useStateRedux('ui');

  const onSearch = useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  const onRemoveEntity = useCallback(
    async (record: any) => {
      if (data.length <= 1) return;
      dispatch(uiHandleOptionalLoading(true));
      const { error } = await removeAccessCustomer({
        email: record.email || record.primaryEmail,
      });
      if (error) {
        dispatch(uiHandleOptionalLoading(false));
        return message.error('Could not remove as administrator');
      }
      refresh();
      message.success('Administrator removed');
      dispatch(uiHandleOptionalLoading(false));
    },
    [dispatch, refresh, data],
  );

  const onAddEntity = useCallback(
    async (record: any) => {
      dispatch(uiHandleOptionalLoading(true));
      const { error } = await addAdminCustomer({
        email: record.email || record.primaryEmail,
      });
      if (error) {
        dispatch(uiHandleOptionalLoading(false));
        return message.error('Could not add as administrator');
      }
      refresh();
      message.success('Administrator added');
      dispatch(uiHandleOptionalLoading(false));
    },
    [dispatch, refresh],
  );

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <div className="signatures__actionsWrapper">
        <Title level={5}>Administrators</Title>
        {
          <SelectPeople
            type="PERSON"
            entities={data}
            onSearch={onSearch}
            searchValue={search}
            addEntity={onAddEntity}
            loading={optionalLoading}
            removeEntity={onRemoveEntity}
          />
        }
      </div>
    </div>
  );
};

export default CollaboratorForm;
