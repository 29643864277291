import React from 'react';
import AdvanceConfig from './advanceConfig';
import InformationForm from './informationForm';
import ChoosePeopleForm from './choosePeopleForm';

const CurrentCreateOutOfficeView = ({ active }: IActionProps) => {
  switch (active) {
    case 'information':
      return <InformationForm />;
    case 'choosePeople':
      return <ChoosePeopleForm />;
    case 'settings':
      return <AdvanceConfig />;

    default:
      return <InformationForm />;
  }
};

interface IActionProps {
  active: 'information' | 'choosePeople' | 'settings' | string;
}

export default CurrentCreateOutOfficeView;
