import React from 'react';
import { Menu } from 'antd';
import { useDispatch } from 'react-redux';
import SubMenu from 'antd/es/menu/SubMenu';
import { useHistory } from 'react-router-dom';
import useStateRedux from 'hooks/useStateRedux';
import { handleMenuAside, handleOpenAside } from 'redux/actions/ui.action';

const MenuAside = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dashboard, aside }: any = useStateRedux('ui');
  const { isMobile, openKeys, selectedKeys } = aside;

  const handleClick = (openKeys: any) =>
    dispatch(handleMenuAside({ openKeys }));
  const onClick = (href: string, selectedKeys: any) => {
    !!isMobile && dispatch(handleOpenAside());
    dispatch(handleMenuAside({ selectedKeys: selectedKeys.keyPath }));
    history.push(href);
  };

  return (
    <div className="mt-2">
      <Menu
        selectable
        onOpenChange={handleClick}
        style={{ borderRight: 'none' }}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        mode="inline"
      >
        {dashboard.map(({ id, name, href, subMenu, ...item }: any) =>
          !!subMenu ? (
            <SubMenu {...item} key={id} title={name}>
              {subMenu.map(({ href, ...i }: any) => (
                <Menu.Item
                  {...i}
                  key={i.id}
                  title={i.name}
                  onClick={(selectedKeys) => onClick(href, selectedKeys)}
                >
                  <a href={href} onClick={(e) => e.preventDefault()}>
                    {i.name}
                  </a>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              {...item}
              key={id}
              title={name}
              onClick={(selectedKeys) => onClick(href, selectedKeys)}
            >
              <a href={href} onClick={(e) => e.preventDefault()}>
                {name}
              </a>
            </Menu.Item>
          ),
        )}
      </Menu>
    </div>
  );
};

export default MenuAside;
