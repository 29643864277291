import { LeftCircleFilled, MenuOutlined } from '@ant-design/icons';
import React from 'react';

interface IProps {
  setShow: () => void;
  show: boolean;
}

const CloseBurger = ({ setShow, show }: IProps) => (
  <div
    className={`dashboard__burger-menu ${
      show ? 'burger-open' : 'burger-close'
    }`}
    onClick={setShow}
  >
    {show ? (
      <LeftCircleFilled className="animate__animated animate__rotateIn animate__faster" />
    ) : (
      <MenuOutlined className="animate__animated animate__rotateIn animate__faster" />
    )}
  </div>
);
export default CloseBurger;
