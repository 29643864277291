import React from 'react';
import { CompletePage } from 'pages';
import Navbar from 'components/navbar/navbar';
import { Route, Switch } from 'react-router-dom';

interface IProv {
  children: React.ReactNode;
}

export const ProviderUerRoles = ({ children }: IProv) => {
  return (
    <div className="dashboard___wrapper-main-content ">
      <div className="dashboard__body bg-content">
        <Navbar brand />
        <div className="dashboard__content beauty-scroll">{children}</div>
      </div>
    </div>
  );
};

const CompleteUserRoutes = () => {
  return (
    <ProviderUerRoles>
      <Switch>
        <Route path="/account/complete" exact component={CompletePage} />
      </Switch>
    </ProviderUerRoles>
  );
};

export default CompleteUserRoutes;
