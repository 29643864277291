import { useDispatch } from 'react-redux';
import { Input, Table, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import ModalShare from './components/modalShare';
import React, { useEffect, useState } from 'react';
import AsideContacts from './components/asideContacts';
import { columnLabels } from 'config/antd/table.config';
import { handleOpenAside } from 'redux/actions/ui.action';

const { Title } = Typography;

const SharedContactPage = () => {
  const dispatch = useDispatch();
  const { aside }: any = useStateRedux('ui');
  const [state, setState] = useState(initialData);

  const handleContact = (id: any) => setState({ ...state, id });
  const handleModal = (visible: boolean) => setState({ ...state, visible });
  const handleOpen = async () => {
    if (!aside.isMobile) await dispatch(handleOpenAside());
    setTimeout(() => setState({ ...state, open: !state.open }), 300);
  };

  useEffect(() => {
    dispatch(handleOpenAside());
  }, [dispatch]);

  console.log(state);

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <div className="shared__wrapper">
        <AsideContacts
          data={data}
          open={state.open}
          setOpen={handleOpen}
          handleModal={handleModal}
          handleContact={handleContact}
        />
        <div className="shared__content">
          <div>
            <Title level={1} style={{ marginBlockEnd: 0 }}>
              Shared Contact {state?.id}
            </Title>
          </div>
          <Input
            style={{ width: 350, maxWidth: '100%' }}
            placeholder="input search text"
          />
          <Table
            className="mt-2"
            dataSource={data}
            columns={columnLabels(handleModal)}
            onRow={(record: any) => ({
              onClick: () => handleContact(record?.id),
            })}
          />
        </div>
      </div>
      <ModalShare visible={state.visible} handleModal={handleModal} />
    </div>
  );
};

const data = [
  {
    id: '123456',
    displayName: 'Developers',
    status: 'Not Shared',
    contacts: false,
  },
];

const initialData = {
  open: true,
  visible: false,
  id: null,
};

export default SharedContactPage;
