import React, { useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import useStateRedux from 'hooks/useStateRedux';
import TimeZone from 'components/input/timeZone';
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Switch,
  Typography,
} from 'antd';

const { Text } = Typography;

const AdvanceConfig = () => {
  const [form] = useForm();
  // const dispatch = useDispatch();
  const { outOffice }: any = useStateRedux('forms');

  const handleActive = (e: any) => {
    console.log(e);
    // dispatch(handleFormSignature({[e.target.name]: e.target.checked}));
  };
  const onChange = () => {};

  useEffect(() => {
    // const newOutOffice = {
    //   ...outOffice,
    //   start_date: !!outOffice.start_date ? moment(outOffice.start_date, 'HH:mm:ss') : '',
    //   execution_date: !!outOffice.execution_date ? moment(outOffice.execution_date) : ''
    // }
    // !!newOutOffice.policy_id && setType(newOutOffice.policy_id)
    form.setFieldsValue(outOffice);
  }, [outOffice, form]);

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <Text className="text-secondary">
        Configure and enable this signature.
      </Text>
      <Form
        form={form}
        name="advance"
        layout="vertical"
        className="mt-2 mr-auto ml-auto"
      >
        <Row gutter={20}>
          <Col xs={24}>
            <Form.Item name="enable" label="Enable Out Office Message">
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={outOffice.enable}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="timezone" label="Timezone">
              <TimeZone
                value={outOffice.timezone}
                onChange={(value: any) => console.log(value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col span={4}>
                <Form.Item label="Active">
                  <Checkbox
                    value={outOffice.enable_end_date}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item name="execution_date" label="End date">
                  <DatePicker
                    disabled
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select start date"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="primary_signature"
              label="Select primary signature (Optional)"
            >
              <Card style={{ width: 'fit-content' }} bodyStyle={{ padding: 0 }}>
                <Checkbox
                  name="primary_signature"
                  onClick={handleActive}
                  checked={outOffice.only_contact}
                  style={{ height: '100%', width: '100%', padding: 10 }}
                >
                  Only send response to people in contacts
                </Checkbox>
              </Card>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Enable automatically" name="active">
              <Card style={{ width: 'fit-content' }} bodyStyle={{ padding: 0 }}>
                <Checkbox
                  name="active"
                  onClick={handleActive}
                  checked={outOffice.only_organization}
                  style={{ height: '100%', width: '100%', padding: 10 }}
                >
                  Only send response to people in organizations
                </Checkbox>
              </Card>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AdvanceConfig;
