import moment from 'moment';
import { weekDays } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import useStateRedux from 'hooks/useStateRedux';
import TimeZone from 'components/input/timeZone';
import { formatTime } from 'helpers/momentConfig';
import React, { useEffect, useState } from 'react';
import { useFetchPolicies } from 'api/signature/signature.api';
import { handleFormSignature } from 'redux/actions/forms.action';
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  TimePicker,
  Typography,
} from 'antd';

const { Text } = Typography;

const AdvanceConfig = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { data, loading } = useFetchPolicies();
  const [type, setType] = useState<any>('');
  const { signature }: any = useStateRedux('forms');

  const handleActive = (e: any) => {
    dispatch(handleFormSignature({ [e.target.name]: e.target.checked }));
  };

  const onChange = () => {
    const policy_id = form.getFieldValue('policy_id');
    setType(policy_id);
  };

  const handleSelect = (name: string, value: any) => {
    if (!value) return;
    dispatch(handleFormSignature({ [name]: value }));
  };

  const formChange = (values: any) => {
    if (values.hasOwnProperty('timezone')) return;
    if (values.hasOwnProperty('primary_signature')) return;
    dispatch(handleFormSignature(values));
  };

  useEffect(() => {
    if (!!data.items.length) {
      const find = data.items.find((i) => i.code_policy === 'ANNUAL');
      setType(find?.id || 'Annual');
    }
  }, [data]);

  useEffect(() => {
    dispatch(handleFormSignature({ policy_id: type }));
  }, [type]);

  useEffect(() => {
    const newSignature = {
      ...signature,
      sync_hours: !!signature.sync_hours
        ? moment(signature.sync_hours, 'HH:mm:ss')
        : '',
      execution_date: !!signature.execution_date
        ? moment(signature.execution_date)
        : '',
    };
    !!newSignature.policy_id && setType(newSignature.policy_id);
    form.setFieldsValue(newSignature);
  }, [signature, form]);

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <Text className="text-secondary">
        Configure and enable this signature.
      </Text>
      <Form
        form={form}
        name="advance"
        layout="vertical"
        onValuesChange={formChange}
        className="mt-2 mr-auto ml-auto"
      >
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item name="policy_id" label="Frequency to set the signature">
              <Select
                size="large"
                value={type}
                loading={loading}
                onChange={onChange}
                placeholder="Choose to frequency"
              >
                {data.items.map(({ id, execution_type }: any) => (
                  <Select.Option key={id} value={id}>
                    {execution_type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <CompleteItem type={type} />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="timezone" label="Timezone">
              <TimeZone
                value={signature.timezone}
                onChange={(value: string) => handleSelect('timezone', value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="execution_date"
              label="Start date"
              extra="Select the date when the frequency to set the signature policy should begin."
            >
              <DatePicker
                size="large"
                style={{ width: '100%' }}
                placeholder="Select start date"
              />
            </Form.Item>
          </Col>
          {/*<Col xs={24} md={12}>*/}
          {/*  <Form.Item*/}
          {/*    name="primary_signature"*/}
          {/*    label="Select primary signature (Optional)"*/}
          {/*  >*/}
          {/*    <Card style={{ width: 'fit-content' }} bodyStyle={{ padding: 0 }}>*/}
          {/*      <Checkbox*/}
          {/*        onClick={handleActive}*/}
          {/*        name="primary_signature"*/}
          {/*        checked={signature.primary_signature}*/}
          {/*        style={{ height: '100%', width: '100%', padding: 10 }}*/}
          {/*      >*/}
          {/*        Set as primary*/}
          {/*      </Checkbox>*/}
          {/*    </Card>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col xs={24} md={12}>
            <Form.Item label="Enable automatically" name="active">
              <Card style={{ width: 'fit-content' }} bodyStyle={{ padding: 0 }}>
                <Checkbox
                  name="active"
                  onClick={handleActive}
                  checked={signature.active}
                  style={{ height: '100%', width: '100%', padding: 10 }}
                >
                  Enable
                </Checkbox>
              </Card>
              <div className="text-secondary">
                Enable to automatically set the signatures to your users based
                on the Frequency selected when editing the signature
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const CompleteItem = ({ type }: any) => {
  const compare = 2;

  if (type === compare)
    return (
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="sync_interval" label="On">
            <Select size="large" placeholder="Select day">
              {weekDays.map((day) => (
                <Select.Option key={day.label} value={day.value}>
                  {day.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="sync_hours" label="At">
            <TimePicker size="large" format={formatTime} />
          </Form.Item>
        </Col>
      </Row>
    );
  else
    return (
      <Form.Item name="sync_hours" label="At">
        <TimePicker size="large" format={formatTime} />
      </Form.Item>
    );
};

export default AdvanceConfig;
