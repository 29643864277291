import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useStateRedux from 'hooks/useStateRedux';
import { columnsSelect } from 'helpers/listDashboard';
import { useFetchEntityList } from 'api/entities/entities.api';
import { Button, Input, Table, Tooltip, Typography } from 'antd';
import {
  addEntitySignature,
  removeEntitySignature,
} from 'redux/actions/forms.action';

const { Text } = Typography;

const ChoosePeopleForm = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { outOffice }: any = useStateRedux('forms');
  const { data, loading } = useFetchEntityList({
    page,
    search,
    available: true,
  });

  const onSearch = (value: string) => setSearch(value);
  const onAddEntity = (record: any) => dispatch(addEntitySignature(record));
  const handleTableChange = (pagination: any) => setPage(pagination.current);
  const onRemoveEntity = (record: any) =>
    dispatch(removeEntitySignature(record.id));

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <div className="signatures__actionsWrapper">
        <TitleHead />
        <div className="card__list-wrapper card__create-form">
          <Input.Search
            allowClear
            size="large"
            onSearch={onSearch}
            style={{ width: 500, maxWidth: '100%' }}
            placeholder="Search by name or email address"
          />
          <div className="options">
            <Tooltip title="Get list from all members in Google Suite">
              <Button
                size="large"
                type="text"
                className="signatures__actionsBtn"
              >
                All
              </Button>
            </Tooltip>
            <Tooltip title="Select a custom group">
              <Button
                size="large"
                type="text"
                className="signatures__actionsBtn"
              >
                Group
              </Button>
            </Tooltip>
            {/*<Tooltip title="Get list from all members in Google Suite">*/}
            {/*  <Button size='large' type='text' className='signatures__actionsBtn'>All</Button>*/}
            {/*</Tooltip>*/}
          </div>
        </div>
      </div>

      <div className="mt-4 signatures__table-wrapper">
        <div className="col-responsive mr-responsive">
          <Text>
            <b>Select persons or group:</b>
          </Text>
          <Table
            className="mt-1"
            loading={loading}
            dataSource={data.items}
            onChange={handleTableChange}
            columns={columnsSelect(onAddEntity, false)}
            pagination={{
              showSizeChanger: false,
              pageSize: 25,
              total: data.total_items,
              current: page,
            }}
          />
        </div>
        <div className="col-responsive">
          <Text>
            <b>Chosen person or groups:</b>
          </Text>
          <Table
            className="mt-1"
            columns={columnsSelect(onRemoveEntity, true)}
            dataSource={outOffice.entities}
          />
        </div>
      </div>
    </div>
  );
};

const TitleHead = () => (
  <div style={{ textAlign: 'left' }}>
    <div className="text-secondary">
      Select the people or Google group to apply this signature.
      <span className="text-secondary">
        {' '}
        When you select a Google group, the signature will be applied to all
        it's members.
      </span>
    </div>
  </div>
);

export default ChoosePeopleForm;
