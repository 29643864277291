import { Button, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { getPayment } from 'api/payment/getPaymentURL';
import getCurrentPayment from 'utils/getCurrentPayment';
import ModalChangePlan from 'components/modal/modalChangePlan';
import { useHistory } from 'react-router-dom';

const initialState = {
  data: null,
  error: false,
  loading: true,
};

const CheckoutPage = () => {
  const history = useHistory();
  const { google }: any = useStateRedux('auth');
  const [visible, setVisible] = useState(false);
  const [state, setState]: any = useState(initialState);

  const cancel = () => history.push('/');
  const handleVisible = () => setVisible(!visible);

  useEffect(() => {
    setState((prev: typeof state) => ({ ...prev, loading: true }));
    const plan = getCurrentPayment(google.plan, google.currentMembership);
    getPayment({ plan: plan }).then(({ data, error }) => {
      !error
        ? setState({ data, loading: false, error: false })
        : setState({ ...state, loading: false, error: error });
      setState((prev: typeof state) => ({ ...prev, loading: false }));
    });
  }, [state.plan, google.plan, google.currentMembership]);

  useEffect(() => {
    if (google.plan === 'Trial') setVisible(true);
  }, []);

  return (
    <>
      <div
        className="animate__animated animate__fadeIn text-right"
        style={{ height: 'calc(100vh - 110px)', position: 'relative' }}
      >
        <div
          className="mb-2"
          style={{ zIndex: 100, position: 'absolute', width: '100%' }}
        >
          <Button className="mr-2" onClick={cancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleVisible}>
            Change Plan
          </Button>
        </div>
        {state.loading ? (
          <Loading />
        ) : state.error ? (
          <div style={{ textAlign: 'center' }}>
            <Typography.Title level={4}>Choose select a Plan</Typography.Title>
            <Button size="large" className="mb-2" onClick={handleVisible}>
              Our plans
            </Button>
          </div>
        ) : (
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={state.data?.payment_uri || ''}
          />
        )}
      </div>

      <ModalChangePlan visible={visible} onCancel={handleVisible} />
    </>
  );
};

export default CheckoutPage;
