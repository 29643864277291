import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteTemplate } from '../api/template/template.api';
import React from 'react';
import { deleteSignature } from '../api/signature/signature.api';

export const onDeleteTemplate = (event: any, item: any, refresh: any) => {
  event.stopPropagation();
  Modal.confirm({
    title: 'Do you want to delete this template?',
    icon: <ExclamationCircleOutlined />,
    content: (
      <span>
        Are you sure to delete <b>{item.name}</b>
      </span>
    ),
    onOk(close) {
      return new Promise(async (resolve, reject) => {
        const { err } = await deleteTemplate(item.id);
        if (err) {
          message.error(err?.message || err);
          close();
          return reject;
        }
        await refresh();
        message.success('Delete successful!');
        close();
        return resolve;
      }).catch(() => console.log('Oops errors!'));
    },
    onCancel() {},
  });
};

export const onDeleteSignature = (event: any, item: any, refresh: any) => {
  event.stopPropagation();
  Modal.confirm({
    title: 'Do you want to delete this signature?',
    icon: <ExclamationCircleOutlined />,
    content: (
      <span>
        Are you sure to delete <b>{item.name}</b>
      </span>
    ),
    onOk(close) {
      return new Promise(async (resolve, reject) => {
        const { err } = await deleteSignature(item.id);
        if (err) {
          message.error(err?.message || err);
          close();
          return reject;
        }
        refresh();
        message.success('Delete successful!');
        close();
        return resolve;
      }).catch(() => console.log('Oops errors!'));
    },
    onCancel() {},
  });
};
