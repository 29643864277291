import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router-dom';
import Editor from 'components/editor/editor';
import useStateRedux from 'hooks/useStateRedux';
import { formTemplate } from 'utils/formConstant';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import ModalMarketPlace from 'components/marketplace/ModalMarketPlace';
import {
  createTemplateAction,
  handleFormSignature,
  handleFormTemplate,
  updateTemplateAction,
} from 'redux/actions/forms.action';

const { Text } = Typography;

const TemplateForm = () => {
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { template }: any = useStateRedux('forms');

  const handleModal = () => setVisible(!visible);

  const onFinish = () => {
    dispatch(handleFormSignature({ save: true }));
    dispatch(
      template.edit.editing
        ? updateTemplateAction(template, history, formTemplate)
        : createTemplateAction(template, history, formTemplate),
    );
  };
  const onValuesChange = (body: any) => dispatch(handleFormTemplate(body));
  const onChangeEditor = (body: any) => {
    dispatch(handleFormTemplate({ body }));
    setVisible(false);
  };

  useEffect(() => form.setFieldsValue(template), [template, form]);

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <Text className="text-secondary">
        Create or edit the signature to be set to user in your domain.
      </Text>
      <Form
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        form={form}
        name={formTemplate}
        className="mt-2"
        layout="vertical"
      >
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item
              required
              name="name"
              label="Title"
              rules={[{ required: true }]}
            >
              <Input placeholder="Title signature" size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Search template">
              <Button
                type="dashed"
                className="btn-primary"
                size="large"
                onClick={handleModal}
              >
                Select template from gallery
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="summary"
              label="Description/Summary"
              rules={[{ required: true }]}
            >
              <Input.TextArea placeholder="Insert a description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div>
        <Editor onChangeEditor={onChangeEditor} data={template.body} />
      </div>
      <ModalMarketPlace
        okText="Apply"
        visible={visible}
        onCancel={handleModal}
        onTemplate={onChangeEditor}
      />
    </div>
  );
};

export default TemplateForm;
