import Loading from '../loading/loading';
import useStateRedux from 'hooks/useStateRedux';
import React, { useEffect, useState } from 'react';
import { getPreviewTemplateHTML } from 'api/preview/previews';
import { useFetchEntityList } from 'api/entities/entities.api';
import { Select } from 'antd';

const { Option } = Select;

const RenderTemplate = ({ body }: IProps) => {
  const { email }: any = useStateRedux('auth');
  const [currentEmail, setCurrent] = useState('');
  const [search, setSearch] = useState(email);
  const [state, setState] = useState({ preview: '', loading: true });
  const { data, loading } = useFetchEntityList({
    search: currentEmail,
    available: true,
    limit: 200,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    getPreviewTemplateHTML({ body, user: search }).then(({ data, error }) => {
      if (error) return setState((prev) => ({ ...prev, loading: false }));
      return setState({ preview: data?.preview || '', loading: false });
    });
  }, [body, search]);

  const onSearch = (val: string) => setCurrent(val);
  const onChange = (val: string) => setSearch(val);

  return (
    <>
      <Select
        showSearch
        loading={loading}
        onSearch={onSearch}
        onChange={onChange}
        searchValue={currentEmail}
        placeholder="Select a person"
        style={{ width: '100%', marginBottom: 20 }}
      >
        {data.items.map((i) => (
          <Option key={i.id} value={i.email}>
            {i.name} | {i.email}
          </Option>
        ))}
      </Select>
      {state.loading ? (
        <Loading />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: state.preview,
          }}
        />
      )}
    </>
  );
};

interface IProps {
  body: string;
}

export default RenderTemplate;
