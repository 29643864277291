export const insertAttr = (editor, name) => {
  //v4
  editor.insertText(`${name} `);

  // v5
  // const selected = editor.model.document.selection;
  // const insertPosition = selected.focus;
  // editor.model.change( writer => {
  // 	writer.insertText( `${name} `, selected.getFirstPosition, insertPosition);
  // } );
};
