import axios from 'config/axios/v1Passport.instance';

export const loginAuth = async (body: any) => {
  return await axios
    .post('/auth/login', body)
    .then(({ data }: any) => {
      console.log(data);
      return { err: false, data };
    })
    .catch((err) => ({
      err: err?.response?.data || { code: 'FAIL', name: err?.message },
      data: null,
    }));
};
