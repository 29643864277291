import React from 'react';
import { Empty } from 'antd';

const EmptyList = () => {
  return (
    <div className="signatures__table-empty">
      <Empty />
    </div>
  );
};

export default EmptyList;
