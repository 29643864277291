import { Pagination, Typography } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotData from 'components/result/notData';
import Loading from 'components/loading/loading';
import { useFetchPeopleList } from 'api/person/person.api';
import CardListPeople from 'components/card/cardListPeople';
import { ActionsNew } from 'components/actionsForm/actionsForm';

const { Title } = Typography;

const PeopleListPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, loading } = useFetchPeopleList(page);

  const syncPeople = () => history.push('/');
  const onClick = (payload: any) => history.push(`/profile/${payload.id}`);
  const handlePagination = (pagination: any) => setPage(pagination);

  const pagination = {
    showSizeChanger: false,
    pageSize: 25,
    total: data.total_items,
    current: page,
  };

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        People List
      </Title>
      <ActionsNew text="Sync People" onClick={syncPeople} />

      <div className="mt-3">
        {loading ? (
          <Loading />
        ) : !data.items.length ? (
          <NotData />
        ) : (
          <>
            {data.items.map((u: any) => (
              <CardListPeople {...u} key={u.id} onClick={onClick} />
            ))}
            <div className="text-right mt-2">
              <Pagination
                {...pagination}
                onChange={handlePagination}
                responsive={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PeopleListPage;
