import React from 'react';
import { Button, Typography } from 'antd';
import NumberList from 'components/NumberList';
import CompleteForm from './components/completeForm';

const { Title } = Typography;

const CompletePage = () => {
  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '100vh' }}
    >
      <Title level={1}>Account</Title>
      <NumberList number={1} title="General" />
      <div>
        <CompleteForm />
        <div className="text-right">
          <Button
            size="large"
            type="primary"
            className="mt-2"
            htmlType="submit"
            form="complete-form"
            style={{ width: 200 }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompletePage;
