import GeneralFrom from './generalFrom';
import AdvanceFrom from './advanceFrom';

const CurrentFormProfile = ({ current }: { current: string }) => {
  switch (current) {
    case 'general-information':
      return <GeneralFrom />;
    case 'advance-information':
      return <AdvanceFrom />;
    default:
      return <GeneralFrom />;
  }
};

export default CurrentFormProfile;
