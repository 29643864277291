import useQuery from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import Editor from 'components/editor/editor';
import useStateRedux from 'hooks/useStateRedux';
import { Col, Form, Input, Row, Typography } from 'antd';
import { handleFormSignature } from 'redux/actions/forms.action';

const { Text } = Typography;

const InformationForm = () => {
  const [form] = useForm();
  const query = useQuery();
  const id = query.get('id');
  const dispatch = useDispatch();
  const { outOffice }: any = useStateRedux('forms');

  const onChangeEditor = (body: any) => dispatch(handleFormSignature({ body }));
  const onValuesChange = (payload: any) =>
    dispatch(handleFormSignature(payload));
  // const handleActive = (e: any) => dispatch(handleFormSignature({active: e.target.checked}))

  useEffect(() => {
    form.setFieldsValue(outOffice);
  }, [outOffice, form]);

  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <Text className="text-secondary">
        Create or edit the out office message and enable at user.
      </Text>
      <Form
        onValuesChange={onValuesChange}
        form={form}
        className="mt-2"
        layout="vertical"
      >
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item name="title" label="Title" required>
              <Input placeholder="Title save message" size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="description" label="Description">
              <Input placeholder="Describe your message." size="large" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="subject" label="Message Subject" required>
              <Input placeholder="Title message Subject." size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Editor
        title="Message Body"
        onChangeEditor={onChangeEditor}
        data={!!id ? outOffice.body : ''}
      />
    </div>
  );
};

export default InformationForm;
