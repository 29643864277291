import React, { useState } from 'react';
import ModalHead from '../head/modalHead';
import { Col, Pagination, Row, Spin } from 'antd';
import CardTemplate from '../card/cardTemplate';
import { useFetchTemplateOrGalleryList } from 'api/template/template.api';

const ListTemplate = ({ selectTemplate, active, onOk }: IListTemplate) => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState<'templates' | 'gallery'>('templates');
  const { data, loading } = useFetchTemplateOrGalleryList(page, type);

  const handlePagination = (pagination: any) => setPage(pagination.current);

  return (
    <>
      <ModalHead title={type} onChangeMenu={(val: any) => setType(val)} />

      <div
        className="beauty-scroll"
        style={{ height: '100%', overflowY: 'scroll', paddingBottom: 185 }}
      >
        <Row style={{ justifyContent: 'center', padding: 5, paddingRight: 20 }}>
          {!loading ? (
            data.items.map((i) => (
              <Col key={i.id}>
                <CardTemplate
                  {...i}
                  active={active === i.id}
                  onClick={() => selectTemplate(i)}
                  ondblclick={() => {
                    selectTemplate(i);
                    onOk();
                  }}
                />
              </Col>
            ))
          ) : (
            <div className="text-center">
              <Spin size="large" />
            </div>
          )}
        </Row>
        <div className="text-center mt-2">
          <Pagination
            pageSize={25}
            current={page}
            showSizeChanger={false}
            total={data.total_items}
            onChange={handlePagination}
          />
        </div>
      </div>
    </>
  );
};

interface IListTemplate {
  active: any;
  onOk: () => void;
  selectTemplate: (template: any) => void;
}

export default ListTemplate;
