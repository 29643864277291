import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import useStateRedux from 'hooks/useStateRedux';
import { Col, Form, Input, Row, Typography } from 'antd';
import { handleFormProfile } from 'redux/actions/forms.action';

const { Title } = Typography;

const GeneralFrom = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { profile }: any = useStateRedux('forms');

  const formChange = (values: any) => {
    if (!!values?.organizations) {
      dispatch(
        handleFormProfile({
          organizations: [
            { ...profile.organizations[0], ...values.organizations },
          ],
        }),
      );
    } else {
      console.log(values);
      // dispatch(
      //   handleFormProfile({
      //     ims: [{ ...profile.ims, ...values.organizations }],
      //   }),
      // );
    }
  };

  useEffect(() => {
    console.log(profile);
    const newProfile = { ...profile };
    newProfile.organizations = newProfile.organizations[0];
    form.setFieldsValue(newProfile);
  }, [profile, form]);

  return (
    <div>
      <Form
        form={form}
        className="mt-3"
        layout="vertical"
        onValuesChange={formChange}
      >
        <Row gutter={[20, 20]}>
          <Col sm={24} md={12}>
            <Title level={3}>ORGANIZATION</Title>

            <Form.Item name={['organizations', 'name']} label="Company">
              <Input placeholder="Aeegle Inc" size="large" />
            </Form.Item>
            <Form.Item name={['organizations', 'title']} label="Title">
              <Input placeholder="Cloud Solutions Specialist" size="large" />
            </Form.Item>
            <Form.Item
              name={['organizations', 'department']}
              label="Department"
            >
              <Input placeholder="Consulting & Development" size="large" />
            </Form.Item>
            <Form.Item name={['organizations', 'location']} label="Location">
              <Input
                placeholder="5ta Calle Pnte #3716, Colonia Escalón, San Salvador"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Title level={3}>OVERVIEW</Title>

            <Form.Item name={['organizations', 'description']} label="Summary">
              <Input.TextArea
                placeholder="This is my tagline"
                size="large"
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GeneralFrom;
