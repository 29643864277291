import { Typography } from 'antd';
import useQuery from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { formTemplate } from 'utils/formConstant';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import TemplateForm from './components/templateForm';
import { Actions } from 'components/actionsForm/actionsForm';
import {
  handleEditFormTemplate,
  handleResetForm,
} from 'redux/actions/forms.action';

const { Title } = Typography;

const CreateOrEditTemplatePage = () => {
  const query = useQuery();
  const id = query.get('id');
  const dispatch = useDispatch();
  const { forms, ui }: any = useStateRedux();
  const { template } = forms;

  useEffect(() => {
    // (if) Launch edit form template
    // (else) Reset clean form
    !!id && dispatch(handleEditFormTemplate({ id }));
  }, [dispatch, id]);

  // Important: Clear editor form  the close page
  useEffect(() => {
    return function cleanup() {
      dispatch(handleResetForm(formTemplate));
    };
  }, []);

  if (ui.loading) return <Loading />;

  return (
    <>
      <div
        className="animate__animated animate__fadeIn"
        style={{ minHeight: '75vh', position: 'relative' }}
      >
        <Title level={1} style={{ marginBlockEnd: 0 }}>
          {template.edit.editing ? 'Edit Template' : 'Create Template'}
        </Title>

        {/*Action Buttons*/}
        <div className="view-desktop">
          <Actions type="template" form={formTemplate} />
        </div>

        {/*Body*/}
        <TemplateForm />
      </div>
    </>
  );
};

export default CreateOrEditTemplatePage;
