import React from 'react';
import moment from 'moment';
import UpgradeSub from './upgradeSub';
import { format } from 'helpers/momentConfig';
import useStateRedux from 'hooks/useStateRedux';
import { Button, Descriptions, Typography } from 'antd';

const Subscription = () => {
  const { licenses, edition, payment_method }: any = useStateRedux('auth');
  return (
    <div>
      <div className="card__list-wrapper card__create-form">
        <div style={{ width: '100%' }}>
          <Descriptions title="Subscription Information" column={2}>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Current Plan"
            >
              {edition}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Payment Method"
            >
              {payment_method}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Expiration Date"
            >
              {moment().format(format)}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Next Charge"
            >
              {moment().format(format)}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Available licenses"
            >
              {licenses}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      {edition !== 'TRIAL' ? (
        <div className="card__list-wrapper card__create-form mt-2 mb-4">
          <div>
            <Typography.Title level={5} className="text-center">
              Unsubscription from plan
            </Typography.Title>
            <Typography.Text>
              From this page you can request Unsubscribe from Zumpul App, For
              this you must click the unsubscribe button, you need to know:
            </Typography.Text>
            <ul className="mt-1 mb-1">
              <li>
                You have full access to zumpul until{' '}
                <b>{moment().format(format)}</b>
              </li>
              <li>
                You can activate your subscription any time, your signatures
                will be in place.
              </li>
            </ul>

            <div className="text-center">
              <Button type="primary" size="large">
                Unsubscription
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <UpgradeSub />
      )}
    </div>
  );
};

export default Subscription;
