import { Modal } from 'antd';
import React, { useState } from 'react';
import ListTemplate from './listTemplate';

const ModalMarketPlace = (props: IProps) => {
  const [selected, setSelected] = useState({ id: null, body: '' });

  const selectTemplate = (template: any) => setSelected(template);
  const onOk = () => props.onTemplate(selected.body);

  return (
    <Modal
      width="97vw"
      style={{ top: '3vh' }}
      okButtonProps={{ disabled: !selected.id }}
      onOk={onOk}
      bodyStyle={{ height: '87vh', overflow: 'hidden', padding: 0 }}
      {...props}
    >
      <ListTemplate
        onOk={onOk}
        selectTemplate={selectTemplate}
        active={selected.id}
      />
    </Modal>
  );
};

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onTemplate: (body: string) => void;

  [key: string]: unknown;
}

export default ModalMarketPlace;
