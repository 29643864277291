export const transformGoogleProfile = (profile: any) => ({
  displayName: `${profile.getName()} ${profile.getFamilyName()}`,
  image: profile.getImageUrl(),
  google_id: profile.getId(),
  email: profile.getEmail(),
});

export const hideEntitiesOfData = (data: any[], entities: any[]) => {
  return data.filter((i) => {
    if (!entities.find(({ id }) => id === i.id)) return i;
  });
};
