import React from 'react';
import { Modal } from 'antd';

const ReuseModal = ({ children, ...props }: IProps) => {
  return (
    <Modal width={720} {...props}>
      {children}
    </Modal>
  );
};

interface IProps {
  visible: boolean;
  children: React.ReactNode;

  [key: string]: unknown;
}

export default ReuseModal;
