import React, { useState } from 'react';
import { Input, Radio, Typography } from 'antd';

const ModalHead = ({ onChangeMenu, title }: IProps) => {
  const [type, setType] = useState('templates');

  const onHandleMenu = (val: any) => {
    setType(val.target.value);
    onChangeMenu(val.target.value);
  };

  return (
    <div className="text-center p-2">
      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        {title}
      </Typography.Title>
      <Radio.Group value={type} onChange={onHandleMenu}>
        <Radio.Button value="templates">My Templates</Radio.Button>
        <Radio.Button value="gallery">Gallery</Radio.Button>
      </Radio.Group>
      <div className="mt-2">
        <Input.Search
          allowClear
          style={{ width: 350, maxWidth: '100%' }}
          placeholder="Name template or description"
        />
      </div>
    </div>
  );
};

interface IProps {
  title: string;
  onChangeMenu: (val: string) => void;
}

export default ModalHead;
